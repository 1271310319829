import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 0.938rem; */
  background-color: #eff4f7;
`;
