import React from "react";

const TrendDirection = ({
  value = 0,
  color = "#48C84E",
  size = "0.875rem",
}) => {
  return value >= 0 ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector 1167"
        d="M6.53033 0.96967C6.23744 0.676777 5.76256 0.676777 5.46967 0.96967L0.696699 5.74264C0.403806 6.03553 0.403806 6.51041 0.696699 6.8033C0.989593 7.09619 1.46447 7.09619 1.75736 6.8033L6 2.56066L10.2426 6.8033C10.5355 7.09619 11.0104 7.09619 11.3033 6.8033C11.5962 6.51041 11.5962 6.03553 11.3033 5.74264L6.53033 0.96967ZM5.25 13.5C5.25 13.9142 5.58579 14.25 6 14.25C6.41421 14.25 6.75 13.9142 6.75 13.5H5.25ZM5.25 1.5L5.25 13.5H6.75L6.75 1.5H5.25Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 14"
    >
      <path
        d="M6.75 1C6.75 0.585786 6.41421 0.25 6 0.25C5.58579 0.25 5.25 0.585786 5.25 1L6.75 1ZM5.46967 13.5303C5.76256 13.8232 6.23744 13.8232 6.53033 13.5303L11.3033 8.75736C11.5962 8.46447 11.5962 7.98959 11.3033 7.6967C11.0104 7.40381 10.5355 7.40381 10.2426 7.6967L6 11.9393L1.75736 7.6967C1.46447 7.40381 0.989593 7.40381 0.696699 7.6967C0.403806 7.98959 0.403806 8.46447 0.696699 8.75736L5.46967 13.5303ZM5.25 1L5.25 13H6.75L6.75 1L5.25 1Z"
        fill={color}
      />
    </svg>
  );
};

export default TrendDirection;
