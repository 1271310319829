import React from "react";

const InvestmentIcon = ({ color = "#505050" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.667 8.11142V8.12031M2.93223 5.78084C2.54238 5.48121 2.24296 5.07962 2.06707 4.62046C1.89119 4.1613 1.84568 3.66245 1.93557 3.17904C2.02547 2.69563 2.24727 2.24649 2.57647 1.88126C2.90567 1.51603 3.32944 1.24893 3.80095 1.10949C4.27245 0.970039 4.77334 0.963674 5.24823 1.0911C5.72313 1.21852 6.15355 1.47476 6.49192 1.83151C6.8303 2.18826 7.06344 2.63162 7.16559 3.11258C7.26774 3.59355 7.23492 4.0934 7.07076 4.55688"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5569 1.88867V5.269C13.657 5.90553 14.4945 6.91289 14.9195 8.11068H16.1114C16.3472 8.11068 16.5733 8.20432 16.74 8.37102C16.9067 8.53771 17.0003 8.7638 17.0003 8.99954V10.7773C17.0003 11.013 16.9067 11.2391 16.74 11.4058C16.5733 11.5725 16.3472 11.6661 16.1114 11.6661H14.9186C14.6199 12.5105 14.1124 13.2661 13.4449 13.8643V15.666C13.4449 16.0196 13.3044 16.3587 13.0544 16.6087C12.8043 16.8588 12.4652 16.9993 12.1116 16.9993C11.758 16.9993 11.4188 16.8588 11.1688 16.6087C10.9188 16.3587 10.7783 16.0196 10.7783 15.666V15.1478C10.4846 15.197 10.1873 15.2216 9.88944 15.2215H6.33401C6.03619 15.2216 5.73888 15.197 5.44515 15.1478V15.666C5.44515 16.0196 5.30468 16.3587 5.05464 16.6087C4.8046 16.8588 4.46547 16.9993 4.11186 16.9993C3.75825 16.9993 3.41913 16.8588 3.16909 16.6087C2.91905 16.3587 2.77858 16.0196 2.77858 15.666V13.8643C1.97324 13.1442 1.40554 12.1967 1.15062 11.1469C0.895698 10.0971 0.965582 8.99471 1.35102 7.98555C1.73645 6.97638 2.41927 6.10805 3.30908 5.49551C4.19889 4.88296 5.25374 4.55507 6.33401 4.55525H8.55615L12.5569 1.88867Z"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InvestmentIcon;
