import React from "react";

const OverviewIcon = ({ color = "#505050" }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.5H9C8.86739 6.5 8.74021 6.55268 8.64645 6.64645C8.55268 6.74021 8.5 6.86739 8.5 7V13C8.5 13.1326 8.55268 13.2598 8.64645 13.3536C8.74021 13.4473 8.86739 13.5 9 13.5H13C13.1326 13.5 13.2598 13.4473 13.3536 13.3536C13.4473 13.2598 13.5 13.1326 13.5 13V7C13.5 6.86739 13.4473 6.74021 13.3536 6.64645C13.2598 6.55268 13.1326 6.5 13 6.5ZM13 0.5H9C8.86739 0.5 8.74021 0.552678 8.64645 0.646447C8.55268 0.740215 8.5 0.867392 8.5 1V3.01C8.5 3.14261 8.55268 3.26979 8.64645 3.36355C8.74021 3.45732 8.86739 3.51 9 3.51H13C13.1326 3.51 13.2598 3.45732 13.3536 3.36355C13.4473 3.26979 13.5 3.14261 13.5 3.01V1C13.5 0.867392 13.4473 0.740215 13.3536 0.646447C13.2598 0.552678 13.1326 0.5 13 0.5ZM5 0.5H1C0.867392 0.5 0.740215 0.552678 0.646447 0.646447C0.552678 0.740215 0.5 0.867392 0.5 1V7C0.5 7.13261 0.552678 7.25979 0.646447 7.35355C0.740215 7.44732 0.867392 7.5 1 7.5H5C5.13261 7.5 5.25979 7.44732 5.35355 7.35355C5.44732 7.25979 5.5 7.13261 5.5 7V1C5.5 0.867392 5.44732 0.740215 5.35355 0.646447C5.25979 0.552678 5.13261 0.5 5 0.5ZM5 10.49H1C0.867392 10.49 0.740215 10.5427 0.646447 10.6364C0.552678 10.7302 0.5 10.8574 0.5 10.99V13C0.5 13.1326 0.552678 13.2598 0.646447 13.3536C0.740215 13.4473 0.867392 13.5 1 13.5H5C5.13261 13.5 5.25979 13.4473 5.35355 13.3536C5.44732 13.2598 5.5 13.1326 5.5 13V10.99C5.5 10.8574 5.44732 10.7302 5.35355 10.6364C5.25979 10.5427 5.13261 10.49 5 10.49Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OverviewIcon;
