import React from "react";

const OthersIcon = ({ color = "#505050" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7624_18411)">
        <path
          d="M2.33333 15L12.3333 15C12.7933 15 13.1533 14.7667 13.3933 14.4133L17 9L13.3933 3.59333C13.1533 3.24 12.7467 3 12.2867 3L2.33333 3C1.6 3 1 3.6 1 4.33333L1 13.6667C1 14.4 1.6 15 2.33333 15ZM2.33333 4.33333L12.2867 4.33333L15.4 9L12.2933 13.6667L2.33333 13.6667L2.33333 4.33333Z"
          fill={color}
        />
        <path
          d="M5 10C5.55228 10 6 9.55228 6 9C6 8.44772 5.55228 8 5 8C4.44772 8 4 8.44772 4 9C4 9.55228 4.44772 10 5 10Z"
          fill={color}
        />
        <path
          d="M8.33594 10C8.88822 10 9.33594 9.55228 9.33594 9C9.33594 8.44772 8.88822 8 8.33594 8C7.78365 8 7.33594 8.44772 7.33594 9C7.33594 9.55228 7.78365 10 8.33594 10Z"
          fill={color}
        />
        <path
          d="M11.6641 10C12.2163 10 12.6641 9.55228 12.6641 9C12.6641 8.44772 12.2163 8 11.6641 8C11.1118 8 10.6641 8.44772 10.6641 9C10.6641 9.55228 11.1118 10 11.6641 10Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_7624_18411">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OthersIcon;
