import React from "react";

const FoodPricesIcon = ({ color = "#505050" }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8923 1H11.1654C11.0184 0.999893 10.8774 1.05797 10.7731 1.16154L1.32308 10.6115C1.11616 10.8195 1 11.1009 1 11.3942C1 11.6876 1.11616 11.969 1.32308 12.1769L5.82308 16.6769C6.03101 16.8838 6.31242 17 6.60577 17C6.89912 17 7.18053 16.8838 7.38846 16.6769L16.8346 7.23077C16.9382 7.12648 16.9963 6.98544 16.9962 6.83846V2.10769C16.9969 1.96235 16.9688 1.81831 16.9137 1.68385C16.8585 1.54939 16.7773 1.42717 16.6747 1.32422C16.5721 1.22127 16.4501 1.13962 16.3159 1.08398C16.1816 1.02834 16.0376 0.999797 15.8923 1Z"
        stroke={color}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9261 5.30724C13.6827 5.30724 13.4447 5.23506 13.2423 5.09982C13.0399 4.96458 12.8822 4.77236 12.789 4.54747C12.6958 4.32257 12.6715 4.07511 12.719 3.83636C12.7665 3.59762 12.8837 3.37831 13.0558 3.20619C13.2279 3.03406 13.4472 2.91684 13.686 2.86935C13.9247 2.82186 14.1722 2.84624 14.3971 2.93939C14.622 3.03254 14.8142 3.1903 14.9494 3.39269C15.0847 3.59509 15.1569 3.83305 15.1569 4.07647C15.1569 4.40289 15.0272 4.71594 14.7964 4.94676C14.5656 5.17757 14.2525 5.30724 13.9261 5.30724Z"
        fill={color}
      />
    </svg>
  );
};

export default FoodPricesIcon;
