import React from "react";

const FoodSecurityIcon = ({ color = "#505050" }) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99777 16L3.46863 14.1183C2.46253 13.583 1.62118 12.7839 1.03487 11.8067C0.448557 10.8295 0.139418 9.71104 0.140629 8.57143V1.14286C0.140931 0.839845 0.261436 0.549331 0.475698 0.335069C0.68996 0.120808 0.980474 0.000302558 1.28349 0H12.7121C13.0151 0.000302558 13.3056 0.120808 13.5198 0.335069C13.7341 0.549331 13.8546 0.839845 13.8549 1.14286V8.57143C13.8561 9.71104 13.547 10.8295 12.9607 11.8067C12.3744 12.7839 11.533 13.583 10.5269 14.1183L6.99777 16ZM1.28349 1.14286V8.57143C1.28254 9.50389 1.53554 10.419 2.01535 11.2185C2.49516 12.0181 3.18364 12.6719 4.00691 13.1097L6.99777 14.7046L9.98863 13.1103C10.812 12.6724 11.5005 12.0185 11.9803 11.2189C12.4602 10.4192 12.7131 9.50399 12.7121 8.57143V1.14286H1.28349Z"
        fill={color}
      />
    </svg>
  );
};

export default FoodSecurityIcon;
