import React from 'react'

export default function NoDataIcon({size = "50"}) {
  return (
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.72786 3.35287L7.5 3.57162V25V46.4284L7.72786 46.6471L7.94661 46.875H25H42.0534L42.2721 46.6471L42.5 46.4284V29.3568V12.2852L37.9154 7.70964L33.3398 3.125H20.6432H7.94661L7.72786 3.35287ZM36.3477 8.22917L39.9935 11.875H36.8672H33.75V9.55078V7.21745L33.5221 6.9987C33.2305 6.69792 32.8112 6.69792 32.5195 6.9987L32.2917 7.21745V10.0521V12.8867L32.5195 13.1055L32.7383 13.3333H36.8945H41.0417V29.375V45.4167H25H8.95833V25V4.58334H20.8346H32.7018L36.3477 8.22917Z" fill="#ABB8D1"/>
<path d="M16.1606 22.6467C15.2582 23.1298 14.9027 24.2236 15.3585 25.0894C15.8416 25.9918 16.9353 26.3473 17.8012 25.8915C18.7035 25.4084 19.059 24.3147 18.6033 23.4488C18.1202 22.5465 17.0264 22.191 16.1606 22.6467Z" fill="#ABB8D1"/>
<path d="M32.2175 22.6467C31.3243 23.1297 30.9597 24.2326 31.4154 25.0803C31.8985 25.9826 33.0013 26.3472 33.8581 25.8915C34.7605 25.4084 35.1159 24.3146 34.6602 23.4488C34.1771 22.5464 33.0834 22.1909 32.2175 22.6467Z" fill="#ABB8D1"/>
<path d="M24.1081 25.8611C22.9596 26.2348 22.0664 27.4197 22.0664 28.5772C22.0664 29.2426 22.7591 29.6163 23.2513 29.2153C23.388 29.1059 23.4792 28.8962 23.5339 28.5681C23.8164 26.7452 26.1497 26.7452 26.4323 28.5681C26.5599 29.3611 27.1888 29.6436 27.6901 29.1423C27.8997 28.9327 27.918 28.8598 27.8724 28.3676C27.7812 27.3741 27.0885 26.3806 26.1862 25.9887C25.6576 25.7517 24.6276 25.6879 24.1081 25.8611Z" fill="#ABB8D1"/>
</svg>


  )
}
