import React from "react";

const Notificationicon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="bell">
<path id="Vector" d="M15 6.66602C15 5.33993 14.4732 4.06816 13.5355 3.13048C12.5979 2.1928 11.3261 1.66602 10 1.66602C8.67392 1.66602 7.40215 2.1928 6.46447 3.13048C5.52678 4.06816 5 5.33993 5 6.66602C5 12.4993 2.5 14.166 2.5 14.166H17.5C17.5 14.166 15 12.4993 15 6.66602Z" fill="#F72D2D"/>
<path id="Vector_2" d="M11.4419 15.5C11.2954 15.7526 11.0851 15.9622 10.8321 16.1079C10.5791 16.2537 10.2922 16.3304 10.0003 16.3304C9.70828 16.3304 9.42142 16.2537 9.1684 16.1079C8.91539 15.9622 8.7051 15.7526 8.55859 15.5" fill="#F72D2D"/>
<circle id="Ellipse 3405" cx="10" cy="15" r="3" fill="#F72D2D"/>
</g>
</svg>

  );
};

export default Notificationicon;
