import React from "react";

const Jordan = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8068_22062)">
        <path
          d="M6.9 13.8C10.7108 13.8 13.8 10.7108 13.8 6.9C13.8 3.08924 10.7108 0 6.9 0C3.08924 0 0 3.08924 0 6.9C0 10.7108 3.08924 13.8 6.9 13.8Z"
          fill="#F0F0F0"
        />
        <path
          d="M4.19834 4.50001H13.3691C12.3942 1.87249 9.86507 0 6.89834 0C4.99292 0 3.26811 0.77245 2.01953 2.02119L4.19834 4.50001Z"
          fill="black"
        />
        <path
          d="M4.19834 9.30078H13.3691C12.3942 11.9283 9.86507 13.8008 6.89834 13.8008C4.99292 13.8008 3.26811 13.0283 2.01953 11.7796L4.19834 9.30078Z"
          fill="#6DA544"
        />
        <path
          d="M2.02096 2.02148C-0.673653 4.7161 -0.673653 9.08495 2.02096 11.7796C3.13447 10.6661 4.2054 9.59515 6.90001 6.90054L2.02096 2.02148Z"
          fill="#D80027"
        />
        <path
          d="M2.73778 5.40039L3.11645 6.19225L3.97164 5.9946L3.58864 6.78435L4.27635 7.32972L3.42013 7.52268L3.4225 8.40038L2.73778 7.85126L2.05304 8.40038L2.05544 7.52268L1.19922 7.32972L1.8869 6.78435L1.50395 5.9946L2.35909 6.19225L2.73778 5.40039Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_8068_22062">
          <rect width="13.8" height="13.8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Jordan;
