import React from "react";

const Timeline = ({ color = "#7B8092" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <g clip-path="url(#clip0_3366_19359)">
        <path
          d="M17.834 6C17.834 6.825 17.159 7.5 16.334 7.5C16.199 7.5 16.0715 7.485 15.9515 7.4475L13.2815 10.11C13.319 10.23 13.334 10.365 13.334 10.5C13.334 11.325 12.659 12 11.834 12C11.009 12 10.334 11.325 10.334 10.5C10.334 10.365 10.349 10.23 10.3865 10.11L8.47399 8.1975C8.35399 8.235 8.21899 8.25 8.08399 8.25C7.94899 8.25 7.81399 8.235 7.69399 8.1975L4.28148 11.6175C4.31898 11.7375 4.33399 11.865 4.33399 12C4.33399 12.825 3.65898 13.5 2.83398 13.5C2.00898 13.5 1.33398 12.825 1.33398 12C1.33398 11.175 2.00898 10.5 2.83398 10.5C2.96898 10.5 3.09648 10.515 3.21648 10.5525L6.63649 7.14C6.59899 7.02 6.58399 6.885 6.58399 6.75C6.58399 5.925 7.25899 5.25 8.08399 5.25C8.90899 5.25 9.58399 5.925 9.58399 6.75C9.58399 6.885 9.56899 7.02 9.53149 7.14L11.444 9.0525C11.564 9.015 11.699 9 11.834 9C11.969 9 12.104 9.015 12.224 9.0525L14.8865 6.3825C14.849 6.2625 14.834 6.135 14.834 6C14.834 5.175 15.509 4.5 16.334 4.5C17.159 4.5 17.834 5.175 17.834 6Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3366_19359">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.583984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Timeline;
