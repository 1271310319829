import React from "react";

const HeaderIcon = ({ fill = "#727272" }) => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 0L0 4V12C0 13.6569 1.34315 15 3 15H9C10.6569 15 12 13.6569 12 12V4L6 0ZM5.55556 14.1538H3.77778C2.8 14.1538 2 13.3846 2 12.4444V11.1624H3.77778C4.75556 11.1624 5.55556 11.9316 5.55556 12.8718V14.1538ZM6.44444 14.1538V12.8718C6.44444 11.9316 7.24444 11.1624 8.22222 11.1624H10V12.4444C10 13.3846 9.2 14.1538 8.22222 14.1538H6.44444ZM5.55556 10.3077H3.77778C2.8 10.3077 2 9.53846 2 8.59829V7.31624H3.77778C4.75556 7.31624 5.55556 8.08547 5.55556 9.02564V10.3077ZM6.44444 10.3077V9.02564C6.44444 8.08547 7.24444 7.31624 8.22222 7.31624H10V8.59829C10 9.53846 9.2 10.3077 8.22222 10.3077H6.44444ZM7 6.39744L6 7.35897L5 6.37607C4.31111 5.71368 4.31111 4.62393 5 3.96154L6 3L7 3.98291C7.68889 4.6453 7.68889 5.73504 7 6.39744Z"
        fill={fill}
      />
    </svg>
  );
};

export default HeaderIcon;
