import React from "react";

const SustainabilityIcon = ({ color = "#505050" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9707 14.2663C10.0427 14.7078 9.02761 14.9356 8 14.9329C4.17707 14.9329 1.06668 11.8225 1.06668 7.9996C1.06668 6.3596 1.63041 4.8108 2.66668 3.56867V4.7996H3.73334V1.59961H0.533347V2.66627H2.04908C0.726766 4.12749 -0.00375982 6.02891 1.45529e-05 7.9996C1.45529e-05 12.4108 3.58881 15.9996 8 15.9996C9.18633 16.0026 10.3582 15.7394 11.4293 15.2295L10.9707 14.2663Z"
        fill={color}
      />
      <path
        d="M10.6698 4.26758C8.98445 4.26758 7.60259 5.57798 7.48152 7.23291C6.89517 6.69794 6.13018 6.40123 5.33646 6.40091H3.20312V8.53424C3.20312 10.299 4.63886 11.7342 6.40312 11.7342H7.46979V13.8676H8.53645V9.6009H9.60312C11.3674 9.6009 12.8031 8.16571 12.8031 6.40091V4.26758H10.6698ZM6.40312 10.6676C5.22659 10.6676 4.26979 9.71077 4.26979 8.53424V7.46757H5.33646C6.51299 7.46757 7.46979 8.42437 7.46979 9.6009V9.91344L6.24685 8.6905L5.49272 9.44464L6.71565 10.6676H6.40312ZM11.7364 6.40091C11.7364 7.57744 10.7796 8.53424 9.60312 8.53424H9.29058L10.5135 7.31131L9.75938 6.55717L8.53645 7.78011V7.46757C8.53645 6.29104 9.49325 5.33424 10.6698 5.33424H11.7364V6.40091Z"
        fill={color}
      />
      <path
        d="M15.9996 8.00001C15.9996 3.58882 12.4108 2.55755e-05 7.99964 2.55755e-05C6.81332 -0.00298704 5.64143 0.260186 4.57031 0.770158L5.02898 1.73336C5.9569 1.29182 6.97203 1.06401 7.99964 1.06669C11.8226 1.06669 14.933 4.17709 14.933 8.00001C14.933 9.64001 14.3692 11.1888 13.333 12.4309V11.2H12.2663V14.4H15.4663V13.3333H13.9506C15.2729 11.8721 16.0034 9.97071 15.9996 8.00001Z"
        fill={color}
      />
    </svg>
  );
};

export default SustainabilityIcon;
