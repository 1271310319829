import React from "react";
import FirstLayout from "../../components/Layout/FIrst/overviewfirstlayout/FirstLayout";

const Overview = () => {
  return (
    <FirstLayout />
  );
};

export default Overview;
