export const countryData = [
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Africa", value: "Africa" },
  { label: "Åland Islands", value: "Åland Islands" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "American Samoa", value: "American Samoa" },
  { label: "Americas", value: "Americas" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Annex I countries", value: "Annex I countries" },
  { label: "Antarctic Region", value: "Antarctic Region" },
  { label: "Antarctica", value: "Antarctica" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Aruba", value: "Aruba" },
  { label: "Asia", value: "Asia" },
  { label: "Australia", value: "Australia" },
  {
    label: "Australia and New Zealand",
    value: "Australia and New Zealand",
  },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belgium-Luxembourg", value: "Belgium-Luxembourg" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  {
    label: "Bolivia (Plurinational State of)",
    value: "Bolivia (Plurinational State of)",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Bouvet Island", value: "Bouvet Island" },
  { label: "Brazil", value: "Brazil" },
  { label: "British Virgin Islands", value: "British Virgin Islands" },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cabo Verde", value: "Cabo Verde" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Caribbean", value: "Caribbean" },
  {
    label: "Caucasus and Central Asia",
    value: "Caucasus and Central Asia",
  },
  { label: "Cayman Islands", value: "Cayman Islands" },
  {
    label: "Central African Republic",
    value: "Central African Republic",
  },
  { label: "Central America", value: "Central America" },
  { label: "Central Asia", value: "Central Asia" },
  {
    label: "Central Asia and Southern Asia",
    value: "Central Asia and Southern Asia",
  },
  { label: "Chad", value: "Chad" },
  { label: "Chagos Archipelago", value: "Chagos Archipelago" },
  { label: "Channel Islands", value: "Channel Islands" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "China, Hong Kong SAR", value: "China, Hong Kong SAR" },
  { label: "China, Macao SAR", value: "China, Macao SAR" },
  { label: "China, mainland", value: "China, mainland" },
  {
    label: "China, Taiwan Province of",
    value: "China, Taiwan Province of",
  },
  { label: "Christmas Island", value: "Christmas Island" },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
  },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo", value: "Congo" },
  { label: "Cook Islands", value: "Cook Islands" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Côte d'Ivoire", value: "Côte d'Ivoire" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Curaçao", value: "Curaçao" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czechia", value: "Czechia" },
  { label: "Czechoslovakia", value: "Czechoslovakia" },
  {
    label: "Democratic People's Republic of Korea",
    value: "Democratic People's Republic of Korea",
  },
  {
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
  },
  { label: "Denmark", value: "Denmark" },
  {
    label:
      "Developed regions (Europe, Cyprus, Israel, Northern America, Japan, Australia & New Zealand)",
    value:
      "Developed regions (Europe, Cyprus, Israel, Northern America, Japan, Australia & New Zealand)",
  },
  { label: "Developing regions", value: "Developing regions" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Eastern Africa", value: "Eastern Africa" },
  { label: "Eastern Asia", value: "Eastern Asia" },
  {
    label: "Eastern Asia (excluding Japan and China)",
    value: "Eastern Asia (excluding Japan and China)",
  },
  {
    label: "Eastern Asia (excluding Japan)",
    value: "Eastern Asia (excluding Japan)",
  },
  {
    label: "Eastern Asia and South-eastern Asia",
    value: "Eastern Asia and South-eastern Asia",
  },
  { label: "Eastern Europe", value: "Eastern Europe" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Eswatini", value: "Eswatini" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Philippines", value: "Philippines" },
  { label: "Pitcairn", value: "Pitcairn" },
  { label: "Poland", value: "Poland" },
  { label: "Polynesia", value: "Polynesia" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  {
    label: "Regional Centres (FAO) (2.5.1.a)",
    value: "Regional Centres (FAO) (2.5.1.a)",
  },
  { label: "Republic of Korea", value: "Republic of Korea" },
  { label: "Republic of Moldova", value: "Republic of Moldova" },
  { label: "Réunion", value: "Réunion" },
  { label: "Romania", value: "Romania" },
  { label: "Russian Federation", value: "Russian Federation" },
  { label: "Rwanda", value: "Rwanda" },
  { label: "Saint Barthélemy", value: "Saint Barthélemy" },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "Saint Helena, Ascension and Tristan da Cunha",
  },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  {
    label: "Saint Martin (French part)",
    value: "Saint Martin (French part)",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { label: "Sark", value: "Sark" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia", value: "Serbia" },
  { label: "Serbia and Montenegro", value: "Serbia and Montenegro" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  {
    label: "Sint Maarten (Dutch part)",
    value: "Sint Maarten (Dutch part)",
  },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  {
    label: "Small Island Developing States",
    value: "Small Island Developing States",
  },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  { label: "South America", value: "South America" },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
  },
  { label: "South Sudan", value: "South Sudan" },
  { label: "South-eastern Asia", value: "South-eastern Asia" },
  { label: "Southern Africa", value: "Southern Africa" },
  { label: "Southern Asia", value: "Southern Asia" },
  {
    label: "Southern Asia (excluding India)",
    value: "Southern Asia (excluding India)",
  },
  { label: "Southern Europe", value: "Southern Europe" },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sub-Saharan Africa", value: "Sub-Saharan Africa" },
  {
    label: "Sub-Saharan Africa (including Sudan)",
    value: "Sub-Saharan Africa (including Sudan)",
  },
  { label: "Sudan", value: "Sudan" },
  { label: "Sudan (former)", value: "Sudan (former)" },
  { label: "Suriname", value: "Suriname" },
  {
    label: "Svalbard and Jan Mayen Islands",
    value: "Svalbard and Jan Mayen Islands",
  },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syrian Arab Republic", value: "Syrian Arab Republic" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Türkiye", value: "Türkiye" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
  },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    label: "United Republic of Tanzania",
    value: "United Republic of Tanzania",
  },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
  },
  {
    label: "United States of America",
    value: "United States of America",
  },
  {
    label: "United States Virgin Islands",
    value: "United States Virgin Islands",
  },
  {
    label: "Upper-middle-income economies",
    value: "Upper-middle-income economies",
  },
  { label: "Uruguay", value: "Uruguay" },
  { label: "USSR", value: "USSR" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  {
    label: "Venezuela (Bolivarian Republic of)",
    value: "Venezuela (Bolivarian Republic of)",
  },
  { label: "Viet Nam", value: "Viet Nam" },
  { label: "Wake Island", value: "Wake Island" },
  {
    label: "Wallis and Futuna Islands",
    value: "Wallis and Futuna Islands",
  },
  { label: "Western Africa", value: "Western Africa" },
  { label: "Western Asia", value: "Western Asia" },
  {
    label:
      "Western Asia (exc. Armenia, Azerbaijan, Cyprus, Israel and Georgia)",
    value:
      "Western Asia (exc. Armenia, Azerbaijan, Cyprus, Israel and Georgia)",
  },
  {
    label: "Western Asia and Northern Africa",
    value: "Western Asia and Northern Africa",
  },
  { label: "Western Europe", value: "Western Europe" },
  { label: "Western Sahara", value: "Western Sahara" },
  { label: "World", value: "World" },
  { label: "Yemen", value: "Yemen" },
  { label: "Yemen Ar Rp", value: "Yemen Ar Rp" },
  { label: "Yemen Dem", value: "Yemen Dem" },
  { label: "Yugoslav SFR", value: "Yugoslav SFR" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
];

export const rightSectionMockData = [
  // {
  //   title: "Production and Trade Alert",
  //   data: [
  //     {
  //       area: "IRAQ",
  //       value: 234,
  //       component: "column",
  //       data: actualOneD,
  //     },
  //     {
  //       area: "EGYPT",
  //       value: 123,
  //       component: "donut",
  //       data: actualOneD,
  //     },
  //     {
  //       area: "SYRIA",
  //       value: 85,
  //       component: "column",
  //       data: actualOneD,
  //     },
  //   ],
  // },
  // {
  //   displayName: "Consumption",
  //   value: "consumption",
  //   data: [
  //     {
  //       area: "Iraq",
  //       iso3_code: "IRQ",
  //       rank: 4,
  //       cluster: 2,
  //       year: "2020",
  //     },
  //     {
  //       area: "Egypt",
  //       iso3_code: "EGY",
  //       rank: 4,
  //       cluster: 2,
  //       year: "2020",
  //     },
  //     {
  //       area: "Syrian Arab Republic",
  //       iso3_code: "SYR",
  //       rank: 4,
  //       cluster: 2,
  //       year: "2020",
  //     },
  //   ],
  // },
  {
    displayName: "Price",
    value: "emission",
    data: [
      {
        area: "Iraq",
        iso3_code: "IRQ",
        rank: 6,
        cluster: 2,
        year: "2020",
        trend: {
          latest_year: "2022",
          isGreen: false,
          year_change: -3,
        },
      },
      {
        area: "Egypt",
        iso3_code: "EGY",
        rank: 5,
        cluster: 2,
        year: "2020",
        trend: {
          latest_year: "2022",
          isGreen: false,
          year_change: -3,
        },
      },
      {
        area: "Syrian Arab Republic",
        iso3_code: "SYR",
        rank: 4,
        cluster: 2,
        year: "2020",
        trend: {
          latest_year: "2022",
          isGreen: false,
          year_change: -3,
        },
      },
      {
        area: "Palestine",
        iso3_code: "PSE",
        rank: 2,
        cluster: 2,
        year: "2020",
        trend: {
          latest_year: "2022",
          isGreen: false,
          year_change: -3,
        },
      },
      {
        area: "Jordan",
        iso3_code: "JOR",
        rank: 3,
        cluster: 2,
        year: "2020",
        trend: {
          latest_year: "2022",
          isGreen: false,
          year_change: -3,
        },
      },
      {
        area: "Lebanon",
        iso3_code: "LBN",
        rank: 1,
        cluster: 2,
        year: "2020",
        trend: {
          latest_year: "2022",
          isGreen: false,
          year_change: -3,
        },
      },
    ],
  },
  // {
  //   displayName: "Forecast of Food Consumption Score",
  //   value: "food_security",
  //   noAlertText: true,
  //   data: [
  //     {
  //       area: "Iraq",
  //       iso3_code: "IRQ",
  //       rank: 6,
  //       cluster: 2,
  //       year: "2020",
  //       trend: {
  //         latest_year: "2022",
  //         isGreen: false,
  //         year_change: -3,
  //       },
  //     },
  //     {
  //       area: "Egypt",
  //       iso3_code: "EGY",
  //       rank: 5,
  //       cluster: 2,
  //       year: "2020",
  //       trend: {
  //         latest_year: "2022",
  //         isGreen: false,
  //         year_change: -3,
  //       },
  //     },
  //     {
  //       area: "Syrian Arab Republic",
  //       iso3_code: "SYR",
  //       rank: 4,
  //       cluster: 2,
  //       year: "2020",
  //       trend: {
  //         latest_year: "2022",
  //         isGreen: false,
  //         year_change: -3,
  //       },
  //     },
  //     {
  //       area: "Palestine",
  //       iso3_code: "PSE",
  //       rank: 2,
  //       cluster: 2,
  //       year: "2020",
  //       trend: {
  //         latest_year: "2022",
  //         isGreen: false,
  //         year_change: -3,
  //       },
  //     },
  //     {
  //       area: "Jordan",
  //       iso3_code: "JOR",
  //       rank: 3,
  //       cluster: 2,
  //       year: "2020",
  //       trend: {
  //         latest_year: "2022",
  //         isGreen: false,
  //         year_change: -3,
  //       },
  //     },
  //     {
  //       area: "Lebanon",
  //       iso3_code: "LBN",
  //       rank: 1,
  //       cluster: 2,
  //       year: "2020",
  //       trend: {
  //         latest_year: "2022",
  //         isGreen: false,
  //         year_change: -3,
  //       },
  //     },
  //   ],
  // },
];

export const TempTrendGraphData = {
  displayName: "Emission",
  value: "emission",
  data: [
    {
      area: "Iraq",
      iso3_code: "IRQ",
      rank: 6,
      cluster: 2,
      year: "2020",
      trend: {
        latest_year: "2022",
        isGreen: false,
        year_change: -3,
        trendData: [
          { year: "2015", value: 982 },
          { year: "2016", value: 754 },
          { year: "2017", value: 632 },
          { year: "2018", value: 489 },
          { year: "2019", value: 876 },
          { year: "2020", value: 123 },
          { year: "2021", value: 567 },
          { year: "2022", value: 345 },
        ],
      },
    },
    {
      area: "Egypt",
      iso3_code: "EGY",
      rank: 5,
      cluster: 2,
      year: "2020",
      trend: {
        latest_year: "2022",
        isGreen: false,
        year_change: -3,
        trendData: [
          { year: "2015", value: 543 },
          { year: "2016", value: 789 },
          { year: "2017", value: 321 },
          { year: "2018", value: 876 },
          { year: "2019", value: 456 },
          { year: "2020", value: 987 },
          { year: "2021", value: 234 },
          { year: "2022", value: 567 },
        ],
      },
    },
    {
      area: "Syrian Arab Republic",
      iso3_code: "SYR",
      rank: 4,
      cluster: 2,
      year: "2020",
      trend: {
        latest_year: "2022",
        isGreen: false,
        year_change: -3,
        trendData: [
          { year: "2015", value: 876 },
          { year: "2016", value: 234 },
          { year: "2017", value: 567 },
          { year: "2018", value: 789 },
          { year: "2019", value: 432 },
          { year: "2020", value: 345 },
          { year: "2021", value: 901 },
          { year: "2022", value: 678 },
        ],
      },
    },
    {
      area: "Palestine",
      iso3_code: "PSE",
      rank: 2,
      cluster: 2,
      year: "2020",
      trend: {
        latest_year: "2022",
        isGreen: false,
        year_change: -3,
        trendData: [
          { year: "2015", value: 456 },
          { year: "2016", value: 789 },
          { year: "2017", value: 123 },
          { year: "2018", value: 567 },
          { year: "2019", value: 890 },
          { year: "2020", value: 234 },
          { year: "2021", value: 678 },
          { year: "2022", value: 901 },
        ],
      },
    },
    {
      area: "Jordan",
      iso3_code: "JOR",
      rank: 4,
      cluster: 2,
      year: "2020",
      trend: {
        latest_year: "2022",
        isGreen: false,
        year_change: -3,
        trendData: [
          { year: "2015", value: 876 },
          { year: "2016", value: 234 },
          { year: "2017", value: 567 },
          { year: "2018", value: 789 },
          { year: "2019", value: 432 },
          { year: "2020", value: 345 },
          { year: "2021", value: 901 },
          { year: "2022", value: 678 },
        ],
      },
    },
    {
      area: "Lebanon",
      iso3_code: "LBN",
      rank: 2,
      cluster: 2,
      year: "2020",
      trend: {
        latest_year: "2022",
        isGreen: false,
        year_change: -3,
        trendData: [
          { year: "2015", value: 456 },
          { year: "2016", value: 789 },
          { year: "2017", value: 123 },
          { year: "2018", value: 567 },
          { year: "2019", value: 890 },
          { year: "2020", value: 234 },
          { year: "2021", value: 678 },
          { year: "2022", value: 901 },
        ],
      },
    },
  ],
  fixedTooltip: true,
};
