import React from "react";

const Area = ({ color = "#fff" }) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.79025 9.64824V10.9178L4.31073 8.3845L6.96729 10.2687L10.6378 9.26566L12.4124 6.16761L14.2752 7.64209L14.5439 7.85561L14.8125 8.06767V12.001L0.894531 12.0683V0.000244141H1.7901V7.69152L5.01856 3.99023L7.86294 6.10518L11.8608 1.37805L14.8127 4.23601V6.92559L14.544 6.71279L14.2753 6.50073L12.1518 4.8199L10.0455 8.49955L7.13944 9.29267L4.21051 7.21569L1.79025 9.64824Z"
        fill={color}
      />
    </svg>
  );
};

export default Area;
